<template lang="pug">
.wrapper.i-wrap
  .section-left
  .section-mid
    .container(v-if="loading" style="justify-content:center")
      Spinner
    transition(name="slideup")
      .user-review-wrap(v-if="!loading")
        h1(style="text-align:center;") 我的評論
        .user-review-list
          .user-review(v-for="review in reviews")
            mtesCard(
              v-if="review.movie"
              :mtes="review.movie"
            )
            Review(
              v-if="review.movie"
              :type="`movie`"
              :id="review.movie._id"
              :review="review"
            )
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { mapActions, mapState } from "vuex";
import mtesCard from "@/components/mtesCard"
import Review from "@/components/Review"

export default {  
  name: 'UserReviews',
  components:{
    Spinner,
    mtesCard,
    Review
    
  },
  data(){
    return{      
      loading:true,
      reviews:[],
    }
  },
  computed:{
    ...mapState(['user']),
  },
  metaInfo(){
    return {
      title: this.loading ? 'Loading...': '我的評論',
      titleTemplate: "%s - 臭豆腐電影"
    }
  },
  methods:{
    async init(){
      this.updateInfo = {...this.user};
      var response = await this.axios.get(`/api/user/myReviews`);
      this.reviews = response.data
      this.loading = false;
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped lang="scss">
  .user-review-list{
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
  }
  .user-review{
    margin:10px;
    max-width:300px;
    flex-grow:0;  
  }
</style>

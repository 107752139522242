<template lang="pug">
div
  .user-review-wrap(v-if="user")
    .user-review-left
      .user-review-text
        textarea-autosize(
          v-if="user.critic == true"
          placeholder="Your Review Summary..."
          ref="myTextarea"
          v-model="content"
          :min-height="50"
          :max-height="250"
        )
        textarea-autosize(
          v-else
          placeholder="Your Review..."
          ref="myTextarea"
          v-model="content"
          :min-height="50"
          :max-height="250"
        )
      .user-critic-link(v-if="user.critic == true")
        label Link to review article:
        br
        input(type="text" placeholder="https://nytimes.com/moviereviews" v-model="articleLink")
      .user-container-wrap
        div(style="display:flex; align-items:center; width:100%;")
          .tofu-group
            .tofuScoreWrap(v-for="tofu in tofuScores")
              .tofuScore(
                v-bind:class="{active: tofu.id == score, awake: tofu.id < score, hover:tofu.id <= tofuHover}" 
                v-on:mouseover="tofuHover = tofu.id" 
                v-on:mouseleave="tofuHover = null"
                v-on:click="(tofuVote(tofu.id))"
              )
          i.del-btn.fas.fa-times-circle.pointer(v-if="score != null" title="刪除評論" @click="deleteReview")
        div(style="width:100%; display:flex; justify-content:flex-end;")
          button.pointer(@click="submitReview") <i class="far fa-comment-alt"></i> 提交
    .user-review-right
      router-link(:to="`/user`")
        img.review-profile(v-if="user.photo" v-bind:src="user.photo")
        img.review-profile(v-else :src="`../assets/img/placeholder_thumb.png`")
        p {{user.displayName}}
  
  .user-review-wrap(v-else )
    .user-review-left
      .user-review-text(@click="pleaseLogIn")
        textarea-autosize(        
          placeholder="Your Review..."
          ref="myTextarea"
          v-model="content"
          :min-height="50"
          :max-height="250"
        )
      .user-container-wrap
        .tofu-group
          .tofuScoreWrap(v-for="tofu in tofuScores")
            .tofuScore(
              v-bind:class="{active: tofu.id == score, awake: tofu.id < score, hover:tofu.id <= tofuHover}" 
              v-on:mouseover="tofuHover = tofu.id" 
              v-on:mouseleave="tofuHover = null"
              v-on:click="(tofuVote(tofu.id))"
            )
        button.pointer(@click="pleaseLogIn") <i class="far fa-comment-alt"></i> 提交
    .user-review-right

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'Review',
  props: {
    type:String,
    id:String,
    review:Object,
    tvSeason:String,
  },
  data(){
    return{
      tofuScores:[{id:5},{id:4},{id:3},{id:2},{id:1}],
      score:null, 
      content:null,
      tofuHover:2,
      reviewId:null,     
      isLoading:true, 
      articleLink:"",
    }
  },
  computed: {
    ...mapState(['user','loggedIn']),
  },
  methods:{
    async pleaseLogIn(){
      if(!this.loggedIn) return this.flash('請登錄', 'error')
    },
    async tofuVote(score){
      if(!this.loggedIn) return this.flash('請登錄', 'error')
      else this.flash('保存...','warning')
      this.score = score;
      let payload = {
        score:this.score,
        critic:false,
        tvSeason:this.tvSeason,
      }
      var vote;
      if (this.user.critic == true) {
        payload.critic = true;
        var lowercase = this.articleLink.toLowerCase();
        if(!lowercase.includes('http')){ 
          return this.flash('please use a http url', 'error')
        }
        else{
          payload.articleLink = lowercase;
          vote = await this.axios.post(`/api/criticReview/${this.type}/${this.id}`,payload)
        }
      }
      else{
        // console.log(this.tvSeason)
        vote = await this.axios.post(`/api/review/${this.type}/${this.id}`,payload)
      }
      if (vote.data.success == true) this.flash('謝謝您，對於您的評論！', 'success')
    },
    async submitReview(){
      this.flash('Saving...','warning')
      if(!this.loggedIn) return this.flash('請登錄', 'error')
      if(this.score){
        let payload = {content:this.content, score:this.score, tvSeason:this.tvSeason}
        let vote;
        if (this.user.critic == true) {
          payload.critic = true;
          payload.articleLink = this.articleLink;
          var lowercase = this.articleLink.toLowerCase();
          if(!lowercase.includes('http')){ 
            return this.flash('please use a http url', 'error')
          }else{
            payload.articleLink = lowercase;
            vote = await this.axios.post(`/api/criticreview/${this.type}/${this.id}`,payload)
          }
        }else{
          vote = await this.axios.post(`/api/review/${this.type}/${this.id}`,payload)
        }
        if(vote.data.success == true) this.flash('謝謝您，對於您的評論！', 'success')
        if(vote.data.error == true) this.flash('An error occured', 'error')
      }else{
        this.flash('請選擇豆腐','error')
      }

    },
    async deleteReview(){
      if(!this.loggedIn) return this.flash('請登錄', 'error')      
      let vote = await this.axios.delete(`/api/review/${this.type}/${this.id}`)
      if(vote.data.success == true){
        this.flash('評論已刪除','success')
        this.score = null;
        this.content = null;
      }else{
        this.flash(vote.data.message,'error')
      }
    }  
  },
  async created(){
    var data;
    try{
      if(this.$route.name == 'UserReviews'){
        data = this.review;
        this.content = data.content;
        this.score = data.score;
        if( this.user.critic == true) {
          this.articleLink = data.articleLink
        }
      }else{
        var response = await this.axios.get(`/api/review/${this.type}/${this.id}`)        
        data = response.data
        if(data.selfReview){ 
          this.content = data.selfReview.content;
          this.score = data.selfReview.score;
          if( this.user.critic == true) {
            this.articleLink = data.selfReview.articleLink
          }
        }
      }
        
      
    }catch(err){
      this.flash(err,'error')
    }    
  }
}
</script>
<style lang="scss" scoped >
.del-btn{
  margin-left:10px;
}
.user-review-wrap{
  display:flex;
  justify-content: space-between;
}
.user-review-text{
  textarea{
    width:calc(100%);
    padding:10px;
    border-radius:5px;
    box-sizing:border-box;
  }
  button{
    margin-top:20px;  
    background-color:white;
    transition:background-color .3s;
    border-radius:7px;  
  }
}
.user-review-left{
  width:calc(80% - 10px);
}
.user-review-right{
  width:calc(20% - 10px);
  text-align:center;
  .review-profile{
    width:90%;
    border-radius: 5px;
  }
  p{
    font-size:12px;
  }
}
.user-container-wrap{
  display:flex;
  // width:fit-content;
  flex-wrap: wrap;
  // justify-content: space-between;
  flex-direction: column;
  margin:20px 5px 20px 0;
  align-items:center;
  button{
    margin:20px 0;
  }
  .tofu-group{
    display:flex;
    flex-direction: row-reverse;

  }
  .tofuScore{
    width:37px;
    height:37px;
    margin:0 3px;
    border-radius:3px;
    cursor:pointer;
    background-image:url('../assets/img/sleepyscore.png');
    background-size:cover;
    box-shadow: 0px 4px 4px -4px grey;
    transition: background-image .3s, box-shadow .4s;
  }
}

.tofuScoreWrap:nth-child(1) .tofuScore {
  animation-delay: .6s;
  animation-duration:1s;
}
.tofuScoreWrap:nth-child(2) .tofuScore {
  animation-delay: .2s;
  animation-duration:.91s;
}
.tofuScoreWrap:nth-child(3) .tofuScore {
  animation-delay: .8s;
  animation-duration:.7s;
}
.tofuScoreWrap:nth-child(4) .tofuScore {
  animation-delay: .15s;
  animation-duration:1.2s;
}
.tofuScoreWrap:nth-child(5) .tofuScore {
  animation-delay: .3s;
  animation-duration:1.5s;
}
.tofu-group .tofuScore.active{
  animation-name: jello, tofuRocking;
  animation-duration: 1s, 5s;
  animation-delay: 0s, 1s;
  animation-iteration-count: 1, infinite;
  // animation-timing-function:ease;
  background-image:url('../assets/img/awakescore1.png');
}
.tofu-group:hover .tofuScore{
  background-image:url('../assets/img/sleepyscore.png');

}
.tofuScore.awake{
  box-shadow: 0px 4px 10px -2px grey;
  background-image:url('../assets/img/awakescore1.png');
  
}
.tofu-group:hover .tofuScore.hover{
  background-image:url('../assets/img/awakescore1.png');
  animation-name: tofuBounce;
  // animation-duration:1s;
  animation-timing-function:ease;
  animation-iteration-count: infinite;
   box-shadow: 0px 4px 10px -2px grey;
}
.tofu-group:hover .tofuScore.active, .tofu-group .tofuScore.active{
  animation-name: jello, tofuRocking;
  animation-duration: 1s, 5s;
  animation-delay: 0s, 1s;
  animation-iteration-count: 1, infinite;
  box-shadow: 0px 4px 10px -2px grey;

}
@keyframes tofuRocking{
  0% {transform:rotate(0deg)}
  25% {transform:rotate(15deg)}
  50% {transform:rotate(-15deg)}
  75% {transform:rotate(15deg)}
  100% {transform:rotate(0deg)}
}
@keyframes tofuWiggle{
  0% {transform:rotate(0deg)}
  5% {transform:rotate(15deg)}
  15% {transform:rotate(-15deg)}
  30% {transform:rotate(5deg)}
  40% {transform:rotate(5deg)}
  50% {transform:rotate(0deg)}
  70% {transform:rotate(0deg)}
  80% {transform:rotate(0deg)}
  90% {transform:rotate(0deg)}
  95% {transform:rotate(0deg)}
  97% {transform:rotate(0deg)}
  99% {transform:rotate(0deg)}
  100% {transform:rotate(0deg)}
}
@keyframes tofuBounce{
	0%{transform:translateY(0)}
	10%{transform:translateY(-10%)}
	20%{transform:translateY(0%)}
	30%{transform:translateY(-20%)}
	40%{transform:translateY(0%)}
	50%{transform:translateY(-5%)}
	55%{transform:translateY(0)}
}
@keyframes jello {
  from, 11.1%, to {transform: none;}
  22.2% {transform: skewX(-20.5deg) skewY(-20.5deg);}
  33.3% {transform: skewX(6.25deg) skewY(6.25deg);}
  44.4% {transform: skewX(-3.125deg) skewY(-3.125deg);}
  55.5% {transform: skewX(1.5625deg) skewY(1.5625deg);}
  66.6% {transform: skewX(-0.78125deg) skewY(-0.78125deg);}
  77.7% {transform: skewX(0.390625deg) skewY(0.390625deg);}
  88.8% {transform: skewX(-0.1953125deg) skewY(-0.1953125deg);}
}
@media screen and(max-width:425px) {
  // .user-container-wrap .tofuScore{
  //   width:25px;
  //   height:25px;
  // }
  .user-container-wrap{
    justify-content: flex-end;
  }
  .user-review-wrap{
    flex-direction:column-reverse;
    align-items: center;
    .user-review-left{
      width:100%;
    }
    .user-review-right{
      width:40%;
      margin-bottom:10px;
    }
    
  }
}
</style>
